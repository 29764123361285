<section class="overview-layout">
    <h1 i18n>Algemene voorwaarden e-loket Mobiliteitsbedrijf</h1>

    <section class="mb-10">
        <ul class="no-style">
            <li>Stad Gent</li>
            <li>IVA Mobiliteitsbedrijf Gent</li>
            <li>Botermarkt 1</li>
            <li>9000 Gent</li>
            <li>Ondernemingsnummer: 0207.451.227</li>
        </ul>
    </section>

    <h2 i18n>Artikel 1: Algemene bepalingen</h2>

    <p>
        §1. Het e-loket “<a href="https://mobiliteitsvergunningen.stad.gent">Mobiliteitsvergunningen</a>” van
        IVA
        Mobiliteitsbedrijf Gent, (hierna 'Mobiliteitsbedrijf') biedt klanten de mogelijkheid om de producten uit
        haar
        webshop online aan te kopen en/of aan te vragen.
    </p>

    <p>
        §2. Deze Algemene Voorwaarden (hierna "Voorwaarden") zijn van toepassing op elke aankoop/aanvraag die
        geplaatst
        wordt door een bezoeker van deze webshop (hierna “Klant” genoemd) en vormen een aanvulling op het
        betreffende
        <a
            href="https://stad.gent/nl/reglementen/reglement-voor-toegang-tot-autovrije-gebieden-en-doorrijdvergunningen">
            Reglement voor toegang tot autovrije gebieden en doorrijdvergunningen
        </a> en
        <a href="https://stad.gent/nl/reglementen/reglement-met-betrekking-tot-de-gemeentelijke-parkeervergunning-0">
            Reglement met betrekking tot de gemeentelijke parkeervergunning
        </a>.
    </p>

    <p>
        §3. Bij een aankoop/aanvraag via de webshop van Mobiliteitsbedrijf, moet de Klant deze Voorwaarden
        uitdrukkelijk
        aanvaarden, waarmee hij instemt met de toepasselijkheid van deze Voorwaarden en de betreffende
        stedelijke
        reglementen.
    </p>

    <p>
        §4. De Klant aanvaardt dat de (elektronische) communicatie en de in de webshop opgeslagen elektronische
        bestanden kunnen worden gebruikt als bewijs van ieder ter zake dienend feit.
    </p>

    <p>
        §5. Mobiliteitsbedrijf behoudt zich het recht voor om de Voorwaarden op om het even welk moment te
        wijzigen. De
        wijzigingen zullen van toepassing zijn vanaf de publicatie ervan op de homepagina van de webshop. Bij
        een
        aankoop/aanvraag na een gepubliceerde wijziging van de Voorwaarden, aanvaardt de Klant de nieuwe
        Voorwaarden.
    </p>

    <h2>Artikel 2: Tarief</h2>

    <p>
        §1. De vergoedingen die van toepassing zijn op de aangekochte/aangevraagde producten, zijn vermeld in
        het
        toepasselijke
        <a href="https://stad.gent/nl/reglementen/retributiereglement-met-betrekking-tot-het-straatparkeren">
            stedelijke retributiereglement
        </a>.
    </p>

    <p>
        §2. Deze tarieven worden tevens vermeld op de webshop, onder voorbehoud van prijswijzigingen. Indien het bedrag
        vermeld op de webshop verschilt van het bedrag vermeld in het retributiereglement, dan geldt het tarief vermeld
        in het retributiereglement.
    </p>

    <h2>Artikel 3: Aanbod</h2>

    <p>
        §1. Het aanbod op de webshop wordt door Mobiliteitsbedrijf met de grootst mogelijke zorgvuldigheid samengesteld
        en up-to-date gehouden. Dit is een middelenverbintenis. Mobiliteitsbedrijf is niet aansprakelijk voor of
        gebonden door materiële vergissingen/fouten en drukfouten in het aanbod op de webshop.
    </p>

    <p>
        §2. Wanneer de Klant specifieke vragen heeft over een product of aanvraag, dan kan de Klant steeds vooraf
        contact opnemen met Mobiliteitsbedrijf.
    </p>

    <h2>Artikel 4: Aankopen/aanvragen via de webshop</h2>

    <p>
        §1. De aanvraagprocedure is vervat in de toepasselijke stedelijke reglementen.
    </p>

    <p class="mb-0">
        §2. De Klant heeft de keuze tussen de volgende betaalwijzen
    </p>

    <section class="mb-10">
        <ul>
            <li>via kredietkaart</li>
            <li>via bankkaart</li>
        </ul>
    </section>

    <p>
        §3. De Klant heeft de mogelijkheid om zijn aankoop/aanvraag af te breken tot voor het klikken op de
        bevestigingsknop onder het aanvaarden van de Voorwaarden.
    </p>

    <p>
        §4. Mobiliteitsbedrijf kan een aankoop en/of aanvraag weigeren ingevolge een ernstige tekortkoming van de Klant
        met betrekking tot zijn/haar aankopen of aanvragen.
    </p>

    <h2>Artikel 5: Herroeping en annulering</h2>
    <p>
        De Klant wordt uitdrukkelijk op de hoogte gesteld dat hij/zij - krachtens artikel VI.53, 12° van het Wetboek van
        Economisch Recht - geen herroepingsrecht kan uitoefenen.
    </p>

    <h2>Artikel 6: Contact en klachtenregeling</h2>

    <p class="mb-0">
        §1. Mobiliteitsbedrijf is voor vragen bereikbaar als volgt:
    </p>

    <ul class="mb-10">
        <li>telefoonnummer 09 266 28 00;</li>
        <li>e-mailadres mobiliteit@stad.gent;</li>
        <li>postadres Stadskantoor, Woodrow Wilsonplein 1, 9000 Gent.</li>
    </ul>

    <p>
        §2. Indien de Klant een klacht heeft over zijn aankoop/aanvraag, dan wendt hij/zij zich eerst rechtstreeks tot
        Mobiliteitsbedrijf (bovenstaande contactgegevens).
    </p>

    <p>
        Mobiliteitsbedrijf bekijkt samen met de Klant of een /welke oplossing geboden kan worden.
    </p>

    <p>
        §3. Voor zover de Klant met Mobiliteitsbedrijf niet tot een oplossing kan komen, staat het de Klant vrij om
        zijn/haar klacht te melden bij de Ombudsvrouw van de Stad Gent:
    </p>

    <ul>
        <li>telefoonnummer 09 266 55 00;</li>
        <li>faxnummer 09 266 55 19;</li>
        <li>e-mailadres ombudsvrouw@stad.gent;</li>
        <li>postadres Botermarkt 17, 9000 Gent.</li>
    </ul>

    <h2>Artikel 7: Privacy</h2>

    <p>
        §1. Mobiliteitsbedrijf behandelt de persoonsgegevens die de Klant invult met respect voor de
        privacy en conform de Algemene Verordening Gegevensbescherming.
    </p>

    <p class="mb-0">
        §2. Waarvoor, met wie en hoe lang?
    </p>

    <p>
        De informatie die door Mobiliteitsbedrijf op het moment van de aankoop/aanvraag wordt
        verzameld, zal enkel gebruikt worden voor de aankoop/aanvraag. De gegevens worden niet aan
        derden doorgegeven.
    </p>

    <p class="mb-0">
        §3. Rechten van de Klant
    </p>

    <p>
        De Klant heeft altijd het recht om zijn/haar gegevens in te zien en om foute gegevens aan te passen.
        Als de Klant wil dat Mobiliteitsbedrijf zijn/haar gegevens niet meer gebruikt, dan kan hij/zij dit
        melden via: <a href="mailto:privacy@stad.gent">privacy@stad.gent</a>. In sommige gevallen kan de Klant
        zijn/haar gegevens laten wissen.
    </p>

    <p>
        Als de Klant vermoedt dat iemand zijn/haar persoonsgegevens onrechtmatig gebruikt, dan kan
        hij/zij dit melden via <a href="mailto:privacy@stad.gent">privacy@stad.gent</a>.
        De Klant heeft ook het recht om klacht in te dienen bij de Vlaamse Toezichtcommissie voor de
        verwerking van persoonsgegevens.
    </p>

    <p>
        Meer informatie over rechten en privacy zijn <a href="https://stad.gent/nl/met-respect-voor-je-privacy">hier</a>
        terug te vinden.
    </p>

    <h2>Artikel 8: Sanctie</h2>

    <p>
        Mobiliteitsbedrijf behoudt zich het recht voor om, ingeval van misbruik van het profiel van de Klant
        of ingeval van andere inbreuken op deze Voorwaarden, het profiel van de Klant te deactiveren of
        ontoegankelijk te maken gedurende maximaal 2 jaar. In dat geval kan de Klant geen aanspraak
        maken op enige vergoeding of compensatie.
    </p>

    <h2>Artikel 9: Aansprakelijkheid</h2>

    <p>
        §1. De Klant die een aankoop/aanvraag doet via de webshop, is verantwoordelijk voor de betaling
        ervan.
    </p>

    <p>§2. De Klant is als enige verantwoordelijk voor het beheer en gebruik van zijn profiel. Foutief
        doorgegeven (persoons)gegevens kunnen aanleiding geven tot een sanctie (zie artikel 8). De Klant
        kan steeds verzoeken om zijn/haar persoonsgegevens te consulteren en aan te passen.
    </p>

    <p>
        §3. Mobiliteitsbedrijf kan niet aansprakelijk worden gesteld voor rechtstreekse of onrechtstreekse
        schade als gevolg van:
    </p>

    <ul>
        <li>het gebruik van de webshop door de Klant;</li>
        <li>een slechte werking of tijdelijke onbeschikbaarheid van de webshop;</li>
        <li>verlies of beschadiging van gegevens op het toestel van de Klant.</li>
    </ul>

    <p>
        §4. Mobiliteitsbedrijf behoudt zich het recht voor om op ieder ogenblik de webshop geheel of
        gedeeltelijk te deactiveren wegens onderhoud of enige andere reden en de inhoud ervan te
        wijzigen, zonder aankondiging of kennisgeving en zonder aan de Klant hiervoor een vergoeding ter
        compensatie verschuldigd te zijn. Mobiliteitsbedrijf is niet aansprakelijk voor eventuele schade ten
        gevolge van dergelijke deactivering of wijziging.
    </p>

    <h2>Artikel 10: Overmacht</h2>

    <p>
        §1. In geval van overmacht is Mobiliteitsbedrijf niet gehouden zijn verplichtingen inzake online
        aankopen en aanvragen na te komen. Mobiliteitsbedrijf is in dat geval gerechtigd haar
        verplichtingen op te schorten voor de duur van de overmacht of de webshop te deactiveren.
    </p>

    <p>
        §2. Overmacht is elke onvoorziene en onvoorzienbare omstandigheid buiten de wil en controle van
        Mobiliteitsbedrijf om, die de nakoming van zijn verplichtingen geheel of gedeeltelijk verhindert.
        Hieronder wordt onder meer begrepen: overstromingen, brand, pandemieën, bedrijfsstoringen,
        energiestoringen, storingen in een (telecommunicatie-) netwerk of verbinding of gebruikte
        communicatiesystemen en/of het op enig moment niet beschikbaar zijn van de webshop. Deze
        opsomming is niet limitatief.
    </p>

    <p>
        §3. Mobiliteitsbedrijf zal de Klant zo snel mogelijk op de hoogte brengen van de overmacht.
        Mobiliteitsbedrijf zal zo snel als redelijkerwijs mogelijk, nadat de oorzaak van de storing of
        vertraging is verholpen, haar verplichtingen nakomen.
    </p>

    <h2>Artikel 11: Aantasting geldigheid - niet-verzaking</h2>

    <p>
        §1. Indien een bepaling van deze Voorwaarden ongeldig, onwettig of nietig wordt verklaard, zal dit
        op geen enkele wijze de geldigheid, de wettigheid en de toepasbaarheid van de andere bepalingen
        aantasten.
    </p>

    <p>
        §2. Het nalaten op gelijk welk moment door Mobiliteitsbedrijf om één van de in deze Voorwaarden
        opgesomde rechten af te dwingen, of gelijk welk recht hiervan uit te oefenen, zal nooit gezien
        worden als een verzaking aan zulke bepaling en zal nooit de geldigheid van deze rechten aantasten.
    </p>

    <h2>Artikel 12: Intellectuele eigendom</h2>

    <p>
        Rekening houdend met de wettelijke bepalingen inzake artistieke en industriële eigendom, zullen
        alle modellen, grafische ontwerpen en informaticasoftware die door Mobiliteitsbedrijf werden
        ontwikkeld, zijn eigendom blijven. Deze mogen in geen geval worden gekopieerd of
        gereproduceerd.
    </p>

    <h2>Artikel 13: Toepasselijk recht - Geschillen</h2>

    <p>
        §1. De overeenkomst tussen de Klant en Mobiliteitsbedrijf wordt enkel beheerst door en
        geïnterpreteerd volgens het Belgische Recht.
    </p>

    <p>
        §2. Indien discussies of geschillen zouden ontstaan in het kader van de aankoop/aanvraag en de
        partijen niet tot een minnelijk akkoord zijn gekomen, kan het geschil worden beslecht door de
        hoven en de rechtbanken van het gerechtelijk arrondissement van Oost-Vlaanderen, afdeling Gent.
    </p>
</section>