<avgpv-pub-status-message [statusMessage]="statusMessage"></avgpv-pub-status-message>

<form [formGroup]="form" (submit)="submit()">
  <p class="form-disclaimer" i18n>Alle velden zijn verplicht, tenzij gemarkeerd als optioneel.</p>

  <div class="form-item" *ngIf="!strongAuth">
    <label for="land" i18n>Land</label>
    <div class="form-columns">
      <div class="form-item-column">
        <select id="land" autocomplete="country" formControlName="landIso2Code">
          <option [ngValue]="land.code" *ngFor="let land of landen$ | async" [innerHTML]="land.naam"></option>
        </select>
      </div>
      <div class="form-item-column">
      </div>
    </div>
  </div>

  <div class="form-item">
    <label for="ondernemingsNummer" i18n>Ondernemingsnummer</label>
    <div class="form-columns">
      <div class="form-item-column">
        <input [attr.inputmode]="form.get('landIso2Code').value === 'BE' ? 'numeric' : null" type="text"
          id="ondernemingsNummer" autocomplete="off" formControlName="ondernemingsNummer" required
          [attr.aria-invalid]="isInvalid('ondernemingsNummer')" [ngClass]="{'error': isInvalid('ondernemingsNummer')}"
          [attr.aria-describedby]="isInvalid('ondernemingsNummer') ? 'ondernemingsNummer--error' : null" />
      </div>
      <div *ngIf="isInvalid('ondernemingsNummer')" class="form-item-column">
        <div *ngIf="form.get('ondernemingsNummer').hasError('required')" class="field-message error" role="alert"
          id="ondernemingsNummer--error" i18n>
          Ondernemingsnummer is een verplicht veld en werd niet ingevuld.
          <div class="accolade"></div>
        </div>
        <div *ngIf="form.get('ondernemingsNummer').hasError('maxlength')" class="field-message error" role="alert"
          id="ondernemingsNummer--error" i18n>
          Het ingevulde Ondernemingsnummer is langer dan ({{form.get('landIso2Code').value}}) <span *ngIf="form.get('landIso2Code').value === 'BE'">10</span><span *ngIf="form.get('landIso2Code').value !== 'BE'">12</span> karakters.
          <div class="accolade"></div>
        </div>
      </div>
    </div>
  </div>

  <div class="form-item">
    <label for="naam" i18n>Naam van de onderneming</label>
    <div class="form-columns">
      <div class="form-item-column">
        <input type="text" id="naam" autocomplete="organization" formControlName="naam" required
          [attr.aria-invalid]="isInvalid('naam')" [ngClass]="{'error': isInvalid('naam')}"
          [attr.aria-describedby]="isInvalid('naam') ? 'naam--error' : null" />
      </div>
      <div *ngIf="isInvalid('naam')" class="form-item-column">
        <div *ngIf="form.get('naam').hasError('required')" class="field-message error" role="alert" id="naam--error"
          i18n>
          Naam is een verplicht veld en werd niet ingevuld.
          <div class="accolade"></div>
        </div>
      </div>
    </div>
  </div>

  <ng-container *ngIf="strongAuth">
    <button id="SyncAddressDataButton" type="button" class="button button-primary mb-10" i18n (click)="fetchAdresData()">Haal mijn adresgegevens
      op</button>
    <p i18n>Door het klikken op de knop, halen we je adresgegevens op uit de kruispuntbank.</p>
  </ng-container>

  <avgpv-pub-address i18n-legend legend="Adres maatschappelijke zetel" uniqueIdPRefix="adresEntiteit"
    [formGroup]="form.get('adresEntiteit')" [straatIsDisabled]="form.get('adresEntiteit.straat')?.disabled" [submitted]="submitted">
  </avgpv-pub-address>

  <div class="form-item">
    <label for="email" i18n>E-mailadres</label>
    <div class="form-columns">
      <div class="form-item-column">
        <input type="email" id="email" autocomplete="email" formControlName="email" required
          [attr.aria-invalid]="isInvalid('email')" [ngClass]="{'error': isInvalid('email')}"
          [attr.aria-describedby]="isInvalid('email') ? 'email--error' : null" />
      </div>
      <div *ngIf="isInvalid('email')" class="form-item-column">
        <div *ngIf="form.get('email').hasError('required')" class="field-message error" role="alert" id="email--error"
          i18n>
          E-mailadres is een verplicht veld en werd niet ingevuld.
          <div class="accolade"></div>
        </div>
      </div>
    </div>
  </div>

  <div class="form-item">
    <label for="telefoon" i18n>Telefoonnummer</label>
    <div class="form-columns">
      <div class="form-item-column">
        <input type="tel" id="telefoon" autocomplete="tel" formControlName="telefoon" required
          [attr.aria-invalid]="isInvalid('telefoon')" [ngClass]="{'error': isInvalid('telefoon')}"
          [attr.aria-describedby]="isInvalid('telefoon') ? 'telefoon--error' : null" />
      </div>
      <div *ngIf="isInvalid('telefoon')" class="form-item-column">
        <div *ngIf="form.get('telefoon').hasError('required')" class="field-message error" role="alert"
          id="telefoon--error" i18n>
          Telefoonnummer is een verplicht veld en werd niet ingevuld.
          <div class="accolade"></div>
        </div>
        <div *ngIf="form.get('telefoon').hasError('pattern')" class="field-message error" role="alert"
          id="telefoon--error" i18n>
          Het ingevulde telefoonnummer is niet geldig. Toegelaten karakters: '+' en cijfers, met maximum lengte van 20. Bv.: +32123456789 of 00321234536789.
          <div class="accolade"></div>
        </div>
      </div>
    </div>
  </div>

  <ng-container [formGroup]="contactpersoon">
    <div class="form-item">
      <label for="cpvoornaam" i18n>Voornaam contactpersoon</label>
      <div class="form-columns">
        <div class="form-item-column">
          <input type="text" id="cpvoornaam" autocomplete="given-name" formControlName="voornaam" required
            [attr.aria-invalid]="isInvalid('voornaam', contactpersoon)"
            [ngClass]="{'error': isInvalid('voornaam', contactpersoon)}"
            [attr.aria-describedby]="isInvalid('voornaam', contactpersoon) ? 'cpvoornaam--error' : null" />
        </div>
        <div *ngIf="isInvalid('voornaam', contactpersoon)" class="form-item-column">
          <div *ngIf="contactpersoon.get('voornaam').hasError('required')" class="field-message error" role="alert"
            id="cpvoornaam--error" i18n>
            Voornaam contactpersoon is een verplicht veld en werd niet ingevuld.
            <div class="accolade"></div>
          </div>
        </div>
      </div>
    </div>

    <div class="form-item">
      <label for="cpnaam" i18n>Naam contactpersoon</label>
      <div class="form-columns">
        <div class="form-item-column">
          <input type="text" id="cpnaam" autocomplete="given-name" formControlName="naam" required
            [attr.aria-invalid]="isInvalid('naam', contactpersoon)"
            [ngClass]="{'error': isInvalid('naam', contactpersoon)}"
            [attr.aria-describedby]="isInvalid('naam', contactpersoon) ? 'cpnaam--error' : null" />
        </div>
        <div *ngIf="isInvalid('naam', contactpersoon)" class="form-item-column">
          <div *ngIf="contactpersoon.get('naam').hasError('required')" class="field-message error" role="alert"
            id="cpnaam--error" i18n>
            Naam contactpersoon is een verplicht veld en werd niet ingevuld.
            <div class="accolade"></div>
          </div>
        </div>
      </div>
    </div>
  </ng-container>

  <p>
    <strong>
      <ng-container i18n>
        De Stad Gent behandelt de persoonsgegevens die
        je invult met respect voor je</ng-container>&nbsp;<a class="pointer" fragment="privacy"
        (click)="focusFragment('privacy')" i18n>privacy </a>.
    </strong>
  </p>

  <div class="form-actions">
    <button type="submit" class="button button-primary button-large" i18n>Bijwerken</button>
  </div>

  <div *ngIf="showPrivacy" id="privacy" class="mt-20" tabindex="-1">
    <h2 i18n>Met respect voor je privacy</h2>
    <p>
      <strong i18n>
        De Stad Gent behandelt de persoonsgegevens die je invult met respect voor je privacy. We volgen hiervoor de <a
          href="https://www.gegevensbeschermingsautoriteit.be/algemene-verordening-gegevensbescherming-burger">
          Algemene Verordening Gegevensbescherming
        </a>.
      </strong>
    </p>

    <h3 i18n>Waarvoor, met wie en hoe lang?</h3>

    <p i18n>Als je de gegevens verzendt, geef je ons toestemming om ze te gebruiken voor de toekenning en verwerking van
      parkeervergunningen en /of vergunningen voor het Autovrij Gebied.</p>

    <p i18n>Persoonsgegevens worden enkel bewaard indien de aanvraag voor een specifieke vergunning gestart wordt.</p>

    <p i18n>We delen ze (enkel) met de online betaalprovider (indien van toepassing) en met de Dienst Inschrijvingen
      Voertuigen
      (DIV).</p>

    <p i18n>We verwijderen je persoonsgegevens na 18 maanden na het verstrijken van de einddatum van de
      aanvraag/vergunning.</p>

    <h3 i18n>Je rechten</h3>

    <p i18n>Je hebt altijd het recht om je persoonsgegevens in te zien en om foute gegevens aan te passen.</p>

    <p i18n>In sommige gevallen kan je ook je persoonsgegevens laten wissen.</p>

    <p i18n>Wil je je beroepen op deze rechten? Dat kan via het <a
        href="https://stad.gent/over-gent-en-het-stadsbestuur/vragen-suggesties-en-meldingen/juridische-info-en-privacy">op
        deze pagina.</a></p>

    <p i18n>Vermoed je dat iemand je persoonsgegevens onrechtmatig gebruikt? Meld het ons via <a
        href="mailto:privacy@stad.gent">privacy@stad.gent</a>. Je hebt ook het recht om klacht in te dienen bij de <a
        href="https://overheid.vlaanderen.be/digitale-overheid/vlaamse-toezichtcommissie">Toezichtcommissie voor de
        verwerking van persoonsgegevens</a> of de <a
        href="https://www.gegevensbeschermingsautoriteit.be/burger">Gegevensbeschermingsautoriteit</a>.
    </p>
  </div>
</form>

<div *ngIf="(loading$ | async)" class="spinner">
  <div i18n>&hellip;loading</div>
</div>
